import { NoSsr } from "@mui/base";
import type { FunctionComponent } from "react";

import { ClientSideSuspense } from "~/components/common/client-side-suspense";
import { HeaderWeather } from "~/components/home/header-weather";
import { useEdition, useEditionValue } from "~/lib/edition";
import { Edition as EditionEnum, headerEditionOptions } from "~/lib/edition/data";
import { formatInTimeZone } from "~/lib/utils";

import {
  Container,
  DateWeather,
  DateWeatherAndBackToOldScmpButton,
  DisplayDate,
  Edition,
  EditionList,
  Wrapper,
} from "./styles";

export type Props = {
  className?: string;
};

export const HomeEdition: FunctionComponent<Props> = ({ className }) => {
  const dateFormat = "MMMM dd, yyyy";
  const dateOfWeekFormat = "EEEE";
  const edition = useEditionValue();
  const { update: updateEdition } = useEdition();

  return (
    <Container className={className}>
      <Wrapper>
        <EditionList>
          <NoSsr>
            {headerEditionOptions.map(option => (
              <Edition
                $isActive={edition === option.value}
                key={option.value}
                onClick={() => {
                  updateEdition(option.value);
                  window.location.href = option.href;
                }}
              >
                {option.label}
              </Edition>
            ))}
          </NoSsr>
        </EditionList>

        <DateWeatherAndBackToOldScmpButton>
          <DateWeather>
            <ClientSideSuspense fallback={<></>}>
              {edition === EditionEnum.HongKong && (
                <>
                  <DisplayDate>
                    <b>{formatInTimeZone(new Date(), dateOfWeekFormat)}</b>
                    <span>{formatInTimeZone(new Date(), dateFormat)}</span>
                  </DisplayDate>
                  <HeaderWeather />
                </>
              )}
            </ClientSideSuspense>
          </DateWeather>
        </DateWeatherAndBackToOldScmpButton>
      </Wrapper>
    </Container>
  );
};

HomeEdition.displayName = "HomeEdition";
