import { useResizeObserver } from "@react-hookz/web";
import { useSetAtom } from "jotai";
import { useCallback, useState } from "react";

import { appBarScrollStickyAtom } from "~/components/app-bar/hooks/sticky/atoms";

export const useAppBarSetStickyScrollHeight = <T extends HTMLElement>() => {
  const setAppBarScrollSticky = useSetAtom(appBarScrollStickyAtom);
  const [element, setElement] = useState<T | null>(null);

  const handleResize = useCallback(
    (element_: T | null) => {
      if (!element_) {
        setAppBarScrollSticky({ stickyScrollHeight: 0 });
        return;
      }

      const rect = element_.getBoundingClientRect();
      setAppBarScrollSticky({ stickyScrollHeight: rect.height ?? 0 });
    },
    [setAppBarScrollSticky],
  );

  const reference = useCallback(
    (node: T) => {
      setElement(node);

      handleResize(node);
    },
    [handleResize],
  );

  useResizeObserver(element, _ => {
    handleResize(element);
  });

  return {
    reference,
  };
};
