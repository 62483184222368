import { NoSsr } from "@mui/base";
import { theme, useResponsive } from "@product/scmp-sdk";
import { useSetAtom } from "jotai";
import { useEffect } from "react";
import type { FunctionComponent } from "react";

import { useShouldHidden } from "~/components/app-bar";
import { HideElementOnScrollEffectThreshold } from "~/components/app-bar/consts";
import { useAppBarContext } from "~/components/app-bar/contexts";
import { useAppBarSetStickyScrollHeight } from "~/components/app-bar/hooks/sticky/app-bar-set-sticky-scroll-height";
import { hamburgerMenuAtom } from "~/components/hamburger-menu/atoms";
import type { HeaderConfiguration } from "~/components/header/types";
import { HomeEdition } from "~/components/home/edition";
import IconMenu from "~/components/home/header/header-icon/menu.svg";
import { sendGATracking } from "~/components/tracking/google-analytics/apis";
import { useEditionValue } from "~/lib/edition";
import { useGetHomeAsPath } from "~/lib/router/hooks";
import { page as HomePage } from "~/pages/home";

import { DynamicHomeNewsTicker } from "./dynamics";
import { useHomeHeader } from "./hooks";
import {
  ActionButton,
  Container,
  Content,
  HeaderContainer,
  HomeHeaderContainer,
  LeftFeatureContainer,
  OnelineMenuContainer,
  RightFeatureContainer,
  StyledDynamicHeaderOnelineMenuContainer,
  StyledHomeMiniHeader,
  StyledLogo,
  StyledLogoLink,
  Wrapper,
} from "./styles";

export type Props = {
  className?: string;
  headerConfiguration?: HeaderConfiguration;
};

export const HomeHeader: FunctionComponent<Props> = ({ className, headerConfiguration }) => {
  const { setShouldSwap } = useAppBarContext();
  const edition = useEditionValue();
  const homeAsPath = useGetHomeAsPath();
  const { reference } = useAppBarSetStickyScrollHeight();
  const setHamburgerMenuState = useSetAtom(hamburgerMenuAtom);

  const isDesktopUp = useResponsive(theme.breakpoints.up("homeDesktop"));
  const threshold = isDesktopUp
    ? HideElementOnScrollEffectThreshold.desktop
    : HideElementOnScrollEffectThreshold.mobile;
  const shouldSwap = useShouldHidden(threshold) ?? false;
  const { mastheadQueryParameter, renderFeatures } = useHomeHeader(shouldSwap, headerConfiguration);

  const { pathname: homePagePathname, query: homePageQuery } = HomePage.route({
    edition,
    slide: "main",
  });

  useEffect(() => {
    if (!setShouldSwap) return;
    setShouldSwap(shouldSwap);
  }, [setShouldSwap, shouldSwap]);

  return (
    <>
      <Container className={className}>
        <HomeHeaderContainer ref={reference}>
          <HomeEdition />
          <HeaderContainer>
            <Wrapper>
              <NoSsr>
                <DynamicHomeNewsTicker />
              </NoSsr>
              <Content>
                <LeftFeatureContainer>
                  <ActionButton
                    $responsiveVariants={{
                      desktopUp: "show",
                      homeDesktopUp: "hidden",
                      largeDesktopUp: "hidden",
                      mobileUp: "show",
                      tabletUp: "show",
                    }}
                    onClick={() => {
                      setHamburgerMenuState(current => ({
                        ...current,
                        isOpen: !current.isOpen,
                        variant: "home",
                      }));
                      sendGATracking({
                        action: "Menu/Open",
                        category: "Menu",
                        label: "Menu/Open",
                      });
                    }}
                  >
                    <IconMenu />
                  </ActionButton>
                  {renderFeatures("left")}
                </LeftFeatureContainer>

                <StyledLogoLink
                  anchorProps={{ "aria-label": "SCMP homepage" }}
                  as={homeAsPath}
                  pathname={homePagePathname}
                  query={{ ...homePageQuery, ...mastheadQueryParameter }}
                >
                  <StyledLogo />
                </StyledLogoLink>
                <RightFeatureContainer>{renderFeatures("right")}</RightFeatureContainer>
              </Content>
              <OnelineMenuContainer>
                <NoSsr>
                  <StyledDynamicHeaderOnelineMenuContainer />
                </NoSsr>
              </OnelineMenuContainer>
            </Wrapper>
          </HeaderContainer>
        </HomeHeaderContainer>
      </Container>

      <StyledHomeMiniHeader $shouldSwap={shouldSwap} />
    </>
  );
};

HomeHeader.displayName = "HomeHeader";
