import { NoSsr } from "@mui/base";
import type { FunctionComponent, SuspenseProps } from "react";
import { Suspense } from "react";

/**
 * This component will render the suspense in CSR for handling suspense boundary issue
 */
export const ClientSideSuspense: FunctionComponent<SuspenseProps> = ({ children, ...props }) => (
  <NoSsr>
    <Suspense {...props}>{children}</Suspense>
  </NoSsr>
);

ClientSideSuspense.displayName = "ClientSideSuspense";
